import "./App.css";

function App() {
  return (
    <div className="App">
      <img
        style={{ maxWidth: "300px" }}
        src="https://cdn.dribbble.com/users/253607/screenshots/1350826/media/3405d128e8edeacb98c49ebc6eacf5b6.png"
      />
    </div>
  );
}

export default App;
